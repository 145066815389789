<template>
  <button
    type="button"
    class="relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
    :class="value ? 'bg-indigo-600' : 'bg-gray-200'"
    role="switch"
    :aria-checked="value"
    @click.prevent="toggle"
  >
    <span class="sr-only">Use setting</span>
    <span
      aria-hidden="true"
      class="pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
      :class="value ? 'translate-x-5' : 'translate-x-0'"
    ></span>
  </button>
</template>

<script>
export default {
  name: 'Toggle',
  props: ['value', 'id'],
  methods: {
    toggle() {
      this.$emit('input', !this.value)
      this.$emit('change', this.id)
    },
  },
}
</script>

<style></style>
